<template>
  <div>
    <p v-if="props.inviteStatus === 'join'" style="font-size: 1.2em">{{ room.group_title }} 그룹에 참여되었습니다. 🤗</p>
    <p v-else-if="props.inviteStatus === 'exists'" style="font-size: 1.2em">{{ room.group_title }} 그룹에 이미 참여되어 있습니다.</p>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  inviteStatus: {
    required: true,
    default: '',
  },
  room: {
    required: true,
  },
})
</script>